import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const UserExperienceImprovements: React.FC<Props> = ({
  data,
  location,
}: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/ux-improvements.jpg)' }}
        ></div>
        <article className="post">
          <h1>User Experience Improvements</h1>
          <h3>Having an ecommerce website is good but we need to sell!</h3>
          <p>
            Multiple factors intervien into an ecommerce website success as
            content, PR, diffusion, SoMe and many others but we can’t forget
            that the path our clients follow until the final transaction need to
            be the easiest, confortable and nicest as possible and for that we
            will bring some quick wins to the website like:
          </p>
          <p>
            <b>Footer</b>: Simplify our footer that contains actually duplicated
            informations and finally deliver only what clients are looking for
            in such section
          </p>
          <LightboxWrapper>
            <a href={'../../img/footer.jpg'}>
              <img
                src={'../../img/footer.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <hr />
          <p>
            <b>Reinssurance bar</b>: We’ve been monitoring a lot of user’s
            trying to click in the 4 blocs we have in this reinssurance bar
            without having information behing all of them. We will so create a
            popin with a short exaplanation text to serve right away a precise
            information to the users.
          </p>
          <LightboxWrapper>
            <a href={'../../img/reinssurance-1.jpg'}>
              <img
                src={'../../img/reinssurance-1.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <LightboxWrapper>
            <a href={'../../img/Reinssurance-popin.jpg'}>
              <img
                src={'../../img/Reinssurance-popin.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <hr />
          <p>
            <b>Cart & Checkout</b>: The checkout will also be redesined to
            simplify and turn even clearer the online last click to the
            transaction. We will also remove all the distractions once a client
            reach the cart and the checkout by removing almost completely the
            header and footer content.
          </p>
          <LightboxWrapper>
            <a href={'../../img/cart.jpg'}>
              <img src={'../../img/cart.jpg'} className="img-fluid thumbnail" />
            </a>
          </LightboxWrapper>
          <LightboxWrapper>
            <a href={'../../img/checkout.jpg'}>
              <img
                src={'../../img/checkout.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>
          <LightboxWrapper>
            <a href={'../../img/thankyou-page.jpg'}>
              <img
                src={'../../img/thankyou-page.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q4 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <blockquote>
            <h4>Need further information?</h4>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/dani-heitor.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <p>
                  Please email{' '}
                  <a href="mailto:dani.heitor@longines.com">
                    dani.heitor@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/nils-lehmann.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <p>
                  Please email{' '}
                  <a href="mailto:nils.lehmann@longines.com">
                    nils.lehmann@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default UserExperienceImprovements

export const pageQuery = graphql`
  query UserExperienceImprovements_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
